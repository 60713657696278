import React, { useState, useEffect } from 'react'; 
import { useParams } from 'react-router-dom';  // Add this import
import styled from 'styled-components';
import axios from 'axios';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';

const LinkedinJobID = () => {
  const { jobId } = useParams();  // Get jobId from URL params
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');



  useEffect(() => {
    if (jobId) {
      setLoading(true);
      setError(null);
      fetchJobDetails(jobId)
        .then(details => {
          if (details) {
            setJobDetails(details.data);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [jobId]);

  const extractJobIdFromUrl = (url) => {
    // Handle both URL formats
    const searchMatch = url.match(/currentJobId=(\d+)/);
    const directMatch = url.match(/jobs\/view\/(\d+)/);
    const cleanDirectMatch = url.match(/(\d+)\/$/); // Add this line for clean number at end
    
    if (searchMatch) return searchMatch[1];
    if (directMatch) return directMatch[1];
    if (cleanDirectMatch) return cleanDirectMatch[1];
    
    // If the input is just a number, return it directly
    if (/^\d+$/.test(url)) return url;
    
    return null;
};
  const fetchJobDetails = async (jobId) => {
    try {
      const response = await axios.get(`${BACKEND_API_URL}/get-job-details`, {
        params: { job_id: jobId },
      });
      return response.data;
    } catch (err) {
      console.error('Error fetching job details:', err);
      setError('Failed to fetch job details. Please try again.');
    }
  };

  const handleUrlSubmit = async () => {
    const jobId = extractJobIdFromUrl(linkedinUrl);
    if (!jobId) {
      setError('Invalid LinkedIn URL');
      return;
    }
    setLoading(true);
    setError(null);
    const jobDetails = await fetchJobDetails(jobId);
    if (jobDetails) {
      setJobDetails(jobDetails.data); // Assuming the API returns a single job detail
    }
    setLoading(false);
  };


  const handleResumeEnhancement = async () => {
    try {
      setLoading(true);
      setError(null);

      const accessToken = localStorage.getItem('authToken');
      if (!accessToken) {
        setError('Please login to use this feature');
        setLoading(false);
        return;
      }
      
      const response = await axios.post(
        `${BACKEND_API_URL}/enhance_resume_from_profile`,
        {
          job_description: jobDetails.description,
          key_achievements: null,
          soft_skills: null,
          technical_skills: null
        },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.data && response.data.file) {
        const binaryData = atob(response.data.file);
        const byteNumbers = new Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          byteNumbers[i] = binaryData.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { 
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
        });
        
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'enhanced_resume.docx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
  
        setSuccessMessage('Resume enhanced successfully!');
      } else {
        throw new Error('No file data received from server');
      }
    } catch (err) {
      console.error('Error enhancing resume:', err);
      if (err.response?.status === 401) {
        setError('Please login to use this feature');
      } else if (err.response?.status === 403) {
        setError('Your subscription is not active. Please check your subscription status.');
      } else if (err.response?.status === 500) {
        setError('Please login to use this feature');
      } else {
        setError(err.response?.data?.detail || 'Failed to enhance resume. Please ensure you have uploaded a resume in your profile.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCoverLetterGeneration = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const accessToken = localStorage.getItem('authToken');
      if (!accessToken) {
        setError('Please login to use this feature');
        setLoading(false);
        return;
      }

      const response = await axios.post(
        `${BACKEND_API_URL}/generate_cover_letter_from_profile`,
        {
          job_description: String(jobDetails.description)
        },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.data && response.data.file) {
        const binaryData = atob(response.data.file);
        const byteNumbers = new Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          byteNumbers[i] = binaryData.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { 
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
        });
        
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'cover_letter.docx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
  
        setSuccessMessage('Cover letter generated successfully!');
      } else {
        throw new Error('No file data received from server');
      }
    } catch (err) {
      console.error('Error generating cover letter:', err);
      if (err.response?.status === 401) {
        setError('Please login to use this feature');
      } else if (err.response?.status === 403) {
        setError('Your subscription is not active. Please check your subscription status.');
      } else if (err.response?.status === 500) {
        setError('Please login to use this feature');
      } else {
        setError(err.response?.data?.detail || 'Failed to generate cover letter. Please ensure you have uploaded a resume in your profile.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBothDocuments = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const accessToken = localStorage.getItem('authToken');
      if (!accessToken) {
        setError('Please login to use this feature');
        setLoading(false);
        return;
      }

      // First generate the resume
      const resumeResponse = await axios.post(
        `${BACKEND_API_URL}/enhance_resume_from_profile`,
        {
          job_description: jobDetails.description,
          key_achievements: null,
          soft_skills: null,
          technical_skills: null
        },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      // Then generate the cover letter
      const coverLetterResponse = await axios.post(
        `${BACKEND_API_URL}/generate_cover_letter_from_profile`,
        {
          job_description: String(jobDetails.description)
        },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      // Handle resume download
      if (resumeResponse.data && resumeResponse.data.file) {
        const binaryData = atob(resumeResponse.data.file);
        const byteNumbers = new Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          byteNumbers[i] = binaryData.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { 
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
        });
        
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'enhanced_resume.docx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
  
      // Handle cover letter download
      if (coverLetterResponse.data && coverLetterResponse.data.file) {
        const binaryData = atob(coverLetterResponse.data.file);
        const byteNumbers = new Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          byteNumbers[i] = binaryData.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { 
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
        });
        
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'cover_letter.docx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
  
      setSuccessMessage('Resume and cover letter generated successfully!');
    } catch (err) {
      console.error('Error generating documents:', err);
      if (err.response?.status === 401) {
        setError('Please login to use this feature');
      } else if (err.response?.status === 403) {
        setError('Your subscription is not active. Please check your subscription status.');
      } else if (err.response?.status === 500) {
        setError('Please login to use this feature');
      } else {
        setError(err.response?.data?.detail || 'Failed to generate documents. Please ensure you have uploaded a resume in your profile and have enough credits.');
      }
    } finally {
      setLoading(false);
    }
  };
  
  



return (
  <Container>
    <WarningMessage>
      ⚠️ Important: Please ensure you have uploaded your resume to your profile before using this feature.
      <WarningLink href="https://www.cvanalyst.io/profile" target="_blank" rel="noopener noreferrer">
        Go to Profile Settings
      </WarningLink>
    </WarningMessage>

    <Card>
      <Section>
        <InputGroup>
          <StyledInput
            type="text"
            value={linkedinUrl}
            onChange={(e) => setLinkedinUrl(e.target.value)}
            placeholder="Paste LinkedIn job URL here"
          />
          <ActionButton onClick={handleUrlSubmit} disabled={loading}>
            Analyze Job
          </ActionButton>
        </InputGroup>
        {error && <ErrorText>{error}</ErrorText>}
        {successMessage && <SuccessText>{successMessage}</SuccessText>}
      </Section>

      {jobDetails && (
        <Section>
          <JobHeader>
            <h2>{jobDetails.title}</h2>
            <CompanyName>{jobDetails.company?.name}</CompanyName>
          </JobHeader>

          <DetailGrid>
            <DetailItem>
              <Label>Location</Label>
              <Value>{jobDetails.location}</Value>
            </DetailItem>
            <DetailItem>
              <Label>Type</Label>
              <Value>{jobDetails.type}</Value>
            </DetailItem>
            <DetailItem>
              <Label>Workplace</Label>
              <Value>{jobDetails.workPlace}</Value>
            </DetailItem>
          </DetailGrid>

          <DetailSection>
            <Label>Description</Label>
            <Description>{jobDetails.description}</Description>
          </DetailSection>

          <DetailSection>
            <Label>Skills</Label>
            <TagContainer>
              {jobDetails.skills?.map((skill, index) => (
                <Tag key={index}>{skill}</Tag>
              )) || 'Not specified'}
            </TagContainer>
          </DetailSection>

          <DetailSection>
            <Label>Job Functions</Label>
            <TagContainer>
              {jobDetails.jobFunctions?.map((func, index) => (
                <Tag key={index}>{func}</Tag>
              )) || 'Not specified'}
            </TagContainer>
          </DetailSection>

          <DetailSection>
            <Label>Industries</Label>
            <TagContainer>
              {jobDetails.formattedIndustries?.map((industry, index) => (
                <Tag key={index}>{industry}</Tag>
              )) || 'Not specified'}
            </TagContainer>
          </DetailSection>

          <ActionGroup>
            <ActionButton onClick={handleResumeEnhancement} disabled={loading}>
              {loading ? 'Enhancing...' : 'Resume Enhancement'}
            </ActionButton>
            <ActionButton onClick={handleCoverLetterGeneration} disabled={loading}>
              {loading ? 'Generating...' : 'Cover Letter Generation'}
            </ActionButton>
            <ActionButton onClick={handleBothDocuments} disabled={loading}>
              {loading ? 'Generating...' : 'Resume + Cover Letter'}
            </ActionButton>
          </ActionGroup>
        </Section>
      )}
    </Card>
  </Container>
);

};

// Styled Components


const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;



const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 2rem;
`;

const Section = styled.div`
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const InputGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const StyledInput = styled.input`
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  font-size: 0.9rem;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const ActionButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #000;
  color: #FFF;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }

  &:disabled {
    background-color: #CCC;
    cursor: not-allowed;
  }
`;

const JobHeader = styled.div`
  margin-bottom: 2rem;
  
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 0 0.5rem 0;
  }
`;

const CompanyName = styled.div`
  font-size: 1.1rem;
  color: #666;
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const DetailItem = styled.div``;

const Label = styled.div`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
`;

const Value = styled.div`
  font-size: 1rem;
`;

const DetailSection = styled.div`
  margin-bottom: 1.5rem;
`;

const Description = styled.div`
  font-size: 0.9rem;
  line-height: 1.6;
  white-space: pre-wrap;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const Tag = styled.span`
  padding: 0.25rem 0.75rem;
  background-color: #F5F5F5;
  border: 1px solid #EAEAEA;
  border-radius: 50px;
  font-size: 0.9rem;
`;

const ActionGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  
  ${ActionButton} {
    flex: 1;
  }
`;

const ErrorText = styled.p`
  color: #DC2626;
  font-size: 0.9rem;
`;

const SuccessText = styled.p`
  color: #059669;
  font-size: 0.9rem;
`;


const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 1rem;
  background-color: #4A25E1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  margin-top: 10px;
`;

const JobDetails = styled.div`
  margin-top: 20px;
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
`;

const WarningMessage = styled.div`
  background: #FFF9E6;
  border: 1px solid #FFE58F;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #D48806;
  font-weight: 500;
`;

const WarningLink = styled.a`
  display: block;
  color: #1890FF;
  margin-top: 0.5rem;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

export default LinkedinJobID;