import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';

const Profile = () => {
    const [resume, setResume] = useState(null);
    const [resumeText, setResumeText] = useState('');
    const [location, setLocation] = useState('');
    const [desiredJobTitle, setDesiredJobTitle] = useState('');
    const [workPreferences, setWorkPreferences] = useState({
      workTypes: ['remote'],
      employmentType: 'full-time',
      salaryExpectation: '',
      willingToRelocate: false,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
  
    // Fetch profile data on component mount
    useEffect(() => {
      fetchProfile();
    }, []);


    const fetchProfile = async () => {
        try {
          const accessToken = localStorage.getItem('authToken');
          const response = await axios.get(`${BACKEND_API_URL}/get-profile`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            }
          });

          // Update all state with fetched data
      const profile = response.data;
      setLocation(profile.location || '');
      setDesiredJobTitle(profile.desired_job_title || '');
      setWorkPreferences({
        workTypes: profile.work_preferences?.workTypes || ['remote'],
        employmentType: profile.employment_type || 'full-time',
        salaryExpectation: profile.salary_expectation_min?.toString() || '',
        willingToRelocate: profile.willing_to_relocate || false,
      });
      
      if (profile.resume_text) {
        setResumeText(profile.resume_text);
      }
    } catch (err) {
      setError('Failed to fetch profile data');
      console.error('Fetch error:', err);
    }
  };


   // Render view mode
   if (!isEditing) {
    return (
      <ProfileContainer>
        <WelcomeMessage>
          <WelcomeTitle>Welcome to CVAnalyst Profile</WelcomeTitle>
          <WelcomeText>
            To use our AI-powered resume and cover letter features, you'll need to:
            <CheckList>
              <CheckItem>✓ Create an account or log in</CheckItem>
              <CheckItem>✓ Upload your current resume</CheckItem>
              <CheckItem>✓ Set your job preferences</CheckItem>
            </CheckList>
          </WelcomeText>
          {!localStorage.getItem('authToken') && (
            <CTAContainer>
              <CTAButton 
                as="a" 
                href="https://analyticpinnacle.thrivecart.com/cv-analyst/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Started Now
              </CTAButton>
              <CTAText>Create your account to unlock all features</CTAText>
            </CTAContainer>
          )}
        </WelcomeMessage>

        <HeaderSection>
          <h1>Profile Settings</h1>
          <EditButton onClick={() => setIsEditing(true)}>Edit Profile</EditButton>
        </HeaderSection>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        
        <Section>
          <h2>Current Profile</h2>
          <InfoGroup>
            <Label>Desired Job Title</Label>
            <Value>{desiredJobTitle || 'Not specified'}</Value>
          </InfoGroup>

          <InfoGroup>
            <Label>Location</Label>
            <Value>{location || 'Not specified'}</Value>
          </InfoGroup>

          <InfoGroup>
            <Label>Work Types</Label>
            <Value>{workPreferences.workTypes.join(', ') || 'Not specified'}</Value>
          </InfoGroup>

          <InfoGroup>
            <Label>Employment Type</Label>
            <Value>{workPreferences.employmentType || 'Not specified'}</Value>
          </InfoGroup>

          <InfoGroup>
            <Label>Salary Expectation</Label>
            <Value>{workPreferences.salaryExpectation || 'Not specified'}</Value>
          </InfoGroup>

          <InfoGroup>
            <Label>Willing to Relocate</Label>
            <Value>{workPreferences.willingToRelocate ? 'Yes' : 'No'}</Value>
          </InfoGroup>

          {resumeText && (
            <InfoGroup>
              <Label>Resume Preview</Label>
              <ResumePreview>{resumeText.substring(0, 200)}...</ResumePreview>
            </InfoGroup>
          )}
        </Section>
      </ProfileContainer>
    );
  }


          
  
  // Handle file upload
  const handleResumeUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Just store the file temporarily, don't upload yet
    setResume(file);
    setResumeText(await file.text());
};

// New function to handle the entire profile save
const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const formData = new FormData();
    if (resume) {
        formData.append('resume', resume);
    }
    
    // Add other profile data
    formData.append('location', location);
    formData.append('desired_job_title', desiredJobTitle);
    formData.append('work_preferences', JSON.stringify(workPreferences));
    formData.append('employment_type', workPreferences.employmentType);      // Changed from employmentType
    formData.append('willing_to_relocate', workPreferences.willingToRelocate);
    formData.append('salary_expectation', workPreferences.salaryExpectation);

    const accessToken = localStorage.getItem('authToken');
    
    try {
        const response = await axios.post(`${BACKEND_API_URL}/save-profile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`,
            }
        });

        setSuccessMessage('Profile updated successfully!');
    } catch (err) {
        setError('Failed to update profile. Please try again.');
        console.error('Update error:', err);
    } finally {
        setLoading(false);
    }
};

  return (
    <ProfileContainer>
      <WelcomeMessage>
        <WelcomeTitle>Welcome to CVAnalyst Profile</WelcomeTitle>
        <WelcomeText>
          To use our AI-powered resume and cover letter features, you'll need to:
          <CheckList>
            <CheckItem>✓ Create an account or log in</CheckItem>
            <CheckItem>✓ Upload your current resume</CheckItem>
            <CheckItem>✓ Set your job preferences</CheckItem>
          </CheckList>
        </WelcomeText>
        {!localStorage.getItem('authToken') && (
          <CTAContainer>
            <CTAButton 
              as="a" 
              href="https://analyticpinnacle.thrivecart.com/cv-analyst/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Started Now
            </CTAButton>
            <CTAText>Create your account to unlock all features</CTAText>
          </CTAContainer>
        )}
      </WelcomeMessage>

      <HeaderSection>
        <h1>Profile Settings</h1>
        <EditButton onClick={() => setIsEditing(true)}>Edit Profile</EditButton>
      </HeaderSection>

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

      <Form onSubmit={handleSubmit}>
        <Section>
          <h2>Resume Upload</h2>
          <FileUploadContainer>
            <FileInput
              type="file"
              accept=".docx"
              onChange={handleResumeUpload}
              disabled={loading}
            />
            {resumeText && <ResumePreview>{resumeText.substring(0, 200)}...</ResumePreview>}
          </FileUploadContainer>
        </Section>

        <Section>
          <h2>Job Preferences</h2>
          <InputGroup>
            <Label>Desired Job Title</Label>
            <Input
              type="text"
              value={desiredJobTitle}
              onChange={(e) => setDesiredJobTitle(e.target.value)}
              placeholder="e.g., Software Engineer"
            />
          </InputGroup>

          <InputGroup>
            <Label>Location</Label>
            <Input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="e.g., New York, NY"
            />
          </InputGroup>

          <InputGroup>
            <Label>Work Type Preferences</Label>
            <Select
                multiple
                value={workPreferences.workTypes}
                onChange={(e) => {
                    const values = Array.from(e.target.selectedOptions, option => option.value);
                    setWorkPreferences({
                        ...workPreferences,
                        workTypes: values
                    });
                }}
            >
                <option value="remote">Remote</option>
                <option value="hybrid">Hybrid</option>
                <option value="onsite">On-site</option>
            </Select>
        </InputGroup>

          <InputGroup>
            <Label>Employment Type</Label>
            <Select
              value={workPreferences.employmentType}
              onChange={(e) => setWorkPreferences({
                ...workPreferences,
                employmentType: e.target.value
              })}
            >
              <option value="full-time">Full-time</option>
              <option value="part-time">Part-time</option>
              <option value="contract">Contract</option>
            </Select>
          </InputGroup>

          <InputGroup>
            <Label>Salary Expectation (Annual)</Label>
            <Input
              type="text"
              value={workPreferences.salaryExpectation}
              onChange={(e) => setWorkPreferences({
                ...workPreferences,
                salaryExpectation: e.target.value
              })}
              placeholder="e.g., $80,000"
            />
          </InputGroup>

          <InputGroup>
            <CheckboxLabel>
              <input
                type="checkbox"
                checked={workPreferences.willingToRelocate}
                onChange={(e) => setWorkPreferences({
                  ...workPreferences,
                  willingToRelocate: e.target.checked
                })}
              />
              Willing to Relocate
            </CheckboxLabel>
          </InputGroup>
        </Section>

        <SubmitButton type="submit" disabled={loading}>
          {loading ? 'Updating...' : 'Save Profile'}
        </SubmitButton>
      </Form>
    </ProfileContainer>
  );
};

// Styled Components
const ProfileContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Section = styled.section`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const FileUploadContainer = styled.div`
  margin-bottom: 1rem;
`;

const FileInput = styled.input`
  margin-bottom: 1rem;
`;

const ResumePreview = styled.div`
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const SubmitButton = styled.button`
  background: purple;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: #fff5f5;
  border-radius: 4px;
`;

const SuccessMessage = styled.div`
  color: green;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: #f0fff4;
  border-radius: 4px;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const EditButton = styled.button`
  background: purple;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background: #6a006a;
  }
`;

const InfoGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Value = styled.div`
  padding: 0.5rem;
  background: #f5f5f5;
  border-radius: 4px;
  margin-top: 0.25rem;
`;

const WelcomeMessage = styled.div`
  background: #F8F9FA;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const WelcomeTitle = styled.h2`
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 1rem;
`;

const WelcomeText = styled.p`
  color: #4A5568;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const CheckList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0;
`;

const CheckItem = styled.li`
  color: #2D3748;
  margin: 0.5rem 0;
  font-weight: 500;
`;

const CTAContainer = styled.div`
  margin-top: 2rem;
`;

const CTAText = styled.p`
  color: #718096;
  margin-top: 0.5rem;
  font-size: 0.9rem;
`;

const CTAButton = styled.button`
  background: #000000;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: all 0.2s ease;

  &:hover {
    background: #333;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding: 1rem;
  }
`;

export default Profile;